import React from 'react';

import './Map.css';
import { colorArray, setUpMap } from './initialization';
import { markersRenderer } from './plugins/markersRenderer';
import TrackLine from './track/TrackLine';

import { L } from '../../types/map';
import { TVesselData } from '../../types/data';
import { TVesselShort } from '../../types/vessel';

type Props = {
  isColored: boolean;
  tracks: TVesselData[];
};


class Map extends React.PureComponent<Props> {
  private static DOM_ID = 'map';
  private readonly colors = [...colorArray].reverse();
  private map: L.Map;
  private tracks: L.Polyline[] = [];

  componentDidMount(): void {
    this.map = setUpMap(Map.DOM_ID);
    this.updateMarkers();
  }

  componentDidUpdate({ tracks }: Props) {
    if (this.props.tracks !== tracks) {
      this.updateMarkers();
    }
  }

  updateMarkers(): void {
    const { tracks } = this.props;
    const edgePoints = [];
    this.clearTracks();

    tracks.forEach((track: TVesselData) =>
      edgePoints.push(track.data[track.data.length - 1])
    );

    const infos = tracks.map((t: TVesselData, index): TVesselShort => ({
      colors: [colorArray[index], colorArray[index]],
      name: t.name
    }));

    markersRenderer(edgePoints.filter(Boolean), this.map, infos);
  }

  clearTracks(): void {
    this.tracks.forEach((track: L.Polyline) => track.remove());
    this.tracks = [];
  }

  renderTrack = (track: TVesselData, index: number) => {
    const { isColored } = this.props;
    const color = isColored ? void 0 : this.colors[index];

    const { country, data, name } = track;
    const _track = {
      data: data,
      vesselId: name + country
    } as any;

    return (
      <TrackLine
        color={color}
        key={name + country}
        map={this.map}
        onClick={Function.prototype as any}
        onRemove={Function.prototype as any}
        track={_track}
      />
    );
  };

  render() {
    const { tracks } = this.props;
    return (
      <div className="Map" id={Map.DOM_ID}>
        <img alt="" className="Map__logo" src="FFMapLogo_color.svg" />
        {tracks?.length > 0 && tracks.map(this.renderTrack)}
      </div>
    );
  }
}


export default Map;
