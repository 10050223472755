import React from 'react';
import './TimeLine.css';

type Props = {
  // Defines current progress, current time position.
  value: number;
  // The final point value.
  maxValue: number;
  // Triggered on the slider drag stop.
  onChange: (value: number) => void;
};


class TimeLine extends React.PureComponent<Props> {
  private static LINE_RIGHT_OFFSET = 20; // Equals the `div.TimeLine__point` width.
  private static POINT_LEFT_OFFSET = 0; // Equals the `div.TimeLine__point` border thickness.

  private line = React.createRef<HTMLDivElement>();
  private point = React.createRef<HTMLDivElement>();
  private shiftX = 0;

  getLineWidth(): number {
    return (
      this.line.current.getBoundingClientRect().width -
      TimeLine.LINE_RIGHT_OFFSET
    );
  }

  getLeftOffset(): number {
    const { maxValue, value } = this.props;
    const _value = value > maxValue ? maxValue : value;

    if (!this.line.current || !this.point.current) {
      return TimeLine.POINT_LEFT_OFFSET;
    }

    const x = (this.getLineWidth() * _value) / maxValue;
    return (x < 0 ? 0 : x) + TimeLine.POINT_LEFT_OFFSET;
  }

  moveAt(pageX: number): void {
    const { maxValue, onChange } = this.props;
    const line = this.line.current;
    const left = pageX - line.offsetLeft - this.shiftX;
    const value = (left * maxValue) / this.getLineWidth();
    onChange(Math.floor(value));
  }

  handleMouseDown = (e) => {
    const point = this.point.current;
    this.shiftX = e.clientX - point.getBoundingClientRect().left + (TimeLine.LINE_RIGHT_OFFSET / 2);

    document.addEventListener('mousemove', this.handleDrag);
    document.addEventListener('mouseup', this.handleEnd);
    this.moveAt(e.pageX);
  };

  handleDrag = (e) => {
    e.stopPropagation();
    this.moveAt(e.pageX);
  };

  handleEnd = (e) => {
    this.shiftX = 0;
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.handleEnd);
  };

  render() {
    const style = { left: this.getLeftOffset() + 'px' };
    const lineStyle = {
      right: this.line.current
        ? this.getLineWidth() - this.getLeftOffset() + 'px'
        : '100%'
    };

    return (
      <section className="TimeLine">
        <main className="TimeLine__main">
          <div
            className="TimeLine__line"
            ref={this.line}
            title="Drag me"
          >
            <div
              className="TimeLine__point"
              ref={this.point}
              onMouseDown={this.handleMouseDown}
              style={style}
            />

            <div className="TimeLine__progress" style={lineStyle} />
          </div>
        </main>
      </section>
    );
  }
}


export default TimeLine;
