import React from 'react';
import classnames from 'classnames';

import './ControlGroup.css';
import { EPlayMode } from '../../types/time';

type Props = {
  onFastForward: () => void;
  onRewind: () => void;
  onPause: () => void;
  onPlay: () => void;
  playMode: EPlayMode;
};


class ControlGroup extends React.PureComponent<Props> {
  handlePlayPause = () => {
    const { onPause, onPlay, playMode } = this.props;
    if (playMode !== EPlayMode.PAUSE && playMode !== EPlayMode.STOP)
      onPause();
    else
      onPlay();
  };

  render() {
    const { onFastForward, onRewind, playMode } = this.props;
    const icon = playMode === EPlayMode.PAUSE || playMode === EPlayMode.STOP
      ? 'play' : 'pause';
    const src = `/player/${icon}.svg`;

    const rwCls = classnames('ControlGroup__btn', {
      'ControlGroup__btn--active': playMode === EPlayMode.REWIND
    });
    const playCls = classnames('ControlGroup__btn', {
      'ControlGroup__btn--active':
        playMode === EPlayMode.PLAY || playMode === EPlayMode.PAUSE
    });
    const ffCls = classnames('ControlGroup__btn', {
      'ControlGroup__btn--active': playMode === EPlayMode.FAST_FORWARD
    });

    return (
      <section className="ControlGroup">
        <button className={rwCls} onClick={onRewind}>
          <img className="button-action-img" src="/player/rewind.svg" alt="rewind" />
        </button>

        <button className={playCls} onClick={this.handlePlayPause}>
          <img className="button-action-img" src={src} alt="play" />
        </button>

        <button className={ffCls} onClick={onFastForward}>
          <img className="button-action-img" src="/player/fast-forward.svg" alt="fast-forward" />
        </button>
      </section>
    );
  }
}

export default ControlGroup;
