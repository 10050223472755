import React from 'react';
import './SpeedLegend.css';

function SpeedLegend() {
  return (
    <section className="SpeedLegend">
      <ul className="SpeedLegend__list">
        <li>
          <div className="SpeedLegend__units">kn</div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s0">
            0 ... &lt;0.3
          </div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s1">
            &gt;=0.3 ... &lt;=1
          </div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s2">
            &gt;1 ... &lt;3
          </div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s3">
            &gt;=3 ... &lt;4
          </div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s4">
            &gt;=4 ... &lt;5.5
          </div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s5">
            &gt;=5.5 ... &lt;=7
          </div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s6">
            &gt;7 ... &lt;10
          </div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s7">
            &gt;=10 ... &lt;=13
          </div>
        </li>
        <li className="SpeedLegend__item">
          <div className="SpeedLegend__color SpeedLegend__color--s8">
            &gt;13
          </div>
        </li>
      </ul>
    </section>
  );
}

export default SpeedLegend;
