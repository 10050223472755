import React from 'react';

import './RateControl.css';
import { EPlayRate } from '../../types/time';

type Props = {
  playRate: EPlayRate;
  onRate: (playRate: EPlayRate) => void;
};


function RateControl({ playRate, onRate }: Props) {
  const handleChange = (e) => onRate(parseInt(e.target.value));

  const options = Object.entries(EPlayRate)
    .filter(([key, value]) => Number.isInteger(value))
    .map(([key, value]) => {
      const isChecked = value === playRate;

      return (
        <div className="RateControl__option" key={key}>
          <input
            checked={isChecked}
            id={key}
            onChange={handleChange}
            name="rate"
            type="radio"
            value={value}
          />

          <label htmlFor={key}>{key.toLowerCase()}</label>
        </div>
      );
    });

  return (
    <section className="RateControl">
      {options}
    </section>
  );
}


export default RateControl;
