import { Chunk, COLOR_MAP, ESpeed, TTrackOptions } from './helpers';
import { L } from '../../../types/map';
import { TTrackPoint, TVesselTrackPoint } from '../../../types/vessel';

/**
 * Draws a track which segments are colored according to the speed.
 */
export class Track {
  // The value, that should be surpassed to display dots.
  public static readonly ZOOM_THRESHOLD = 11;
  public readonly options: TTrackOptions;
  private points: L.CircleMarker[];
  private segmentsVisible: L.Polyline[];

  private static defineSpeed(speed: number): number {
    if (speed < 0.3) {
      return ESpeed.S0;
    } else if (speed >= 0.3 && speed <= 1) {
      return ESpeed.S1;
    } else if (speed > 1 && speed < 3) {
      return ESpeed.S2;
    } else if (speed >= 3 && speed < 4) {
      return ESpeed.S3;
    } else if (speed >= 4 && speed < 5.5) {
      return ESpeed.S4;
    } else if (speed >= 5.5 && speed <= 7) {
      return ESpeed.S5;
    } else if (speed > 7 && speed < 10) {
      return ESpeed.S6;
    } else if (speed >= 10 && speed <= 13) {
      return ESpeed.S7;
    }
    return ESpeed.S8;
  }

  private static data2points(data: TTrackPoint[]): [number, number][] {
    if (!data) return [];
    return data.map(({ lat, lng }) => [lat, lng]);
  }

  constructor(options: TTrackOptions) {
    this.options = options;
    this.drawSegments(this.createChunks());
  }

  private onPointClick = (e: L.LeafletMouseEvent) => {
    const {
      onClick,
      path: { vesselId }
    } = this.options;
    const {
      latlng,
      sourceTarget: { options }
    } = e;
    onClick(vesselId, options.point, latlng);
  };

  private onLineClick = (e: L.LeafletMouseEvent) => {
    const { map, onClick, path } = this.options;
    const {
      latlng,
      target: { _latlngs }
    } = e;

    const distArr = _latlngs.map((ll) => map.distance(ll, latlng));
    const minDist = Math.min(...distArr);
    const pointIndex = distArr.findIndex((d) => d === minDist);

    onClick(path.vesselId, path.data[pointIndex], latlng);
  };

  public remove() {
    this.points?.forEach((p) => p.remove());
    this.segmentsVisible?.forEach((s) => s.remove());
  }

  private createChunks(): Chunk[] {
    let currentSpeed;
    let currentChunkIdx = -1;
    const chunks: Chunk[] = [];

    this.options.path.data?.forEach((p: TVesselTrackPoint) => {
      const speedRange = Track.defineSpeed(p.speed);

      // Start a new chunk
      if (currentSpeed !== speedRange) {
        if (currentChunkIdx >= 0) {
          chunks[currentChunkIdx].data.push(p);
        }
        currentSpeed = speedRange;
        currentChunkIdx++;

        const chunk = this.createChunk(speedRange);
        chunk.data[0] = p;
        chunks[currentChunkIdx] = chunk;
      } else {
        chunks[currentChunkIdx].data.push(p);
      }
    });

    return chunks;
  }

  private createChunk(speedRange: number): Chunk {
    return {
      color: this.options.color || COLOR_MAP[speedRange],
      data: []
    };
  }

  private drawSegments(arr: Chunk[]): void {
    this.segmentsVisible = arr.map(
      (ch: Chunk): L.Polyline =>
        window.L.polyline(Track.data2points(ch.data as any), {
          color: ch.color,
          weight: 2
        }).addTo(this.options.map)
    );
  }
}
