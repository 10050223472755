import format from 'date-fns/format';

export const SEC_AS_MS = 1000;
export const MIN_AS_MS = SEC_AS_MS * 60;

// Defines the playback speed:
// 1000 / UPDATE_INTERVAL == frames per second.
export const UPDATE_INTERVAL = 100; // milliseconds
export const UPDATE_PERIOD = 120; // seconds


export function formatDateTime(ms: number): string {
  const date = new Date(ms + getTimeOffset());
  return format(date, 'yyyy MMM dd, HH:mm:ss') + ' UTC';
}


export function getCurrentMs(startTime: number, currentIndex: number): number {
  return startTime + currentIndex * UPDATE_PERIOD * SEC_AS_MS;
}


export function getMaxIndex(startTime: number, endTime: number): number {
  return Math.ceil((endTime - startTime) / (UPDATE_PERIOD * SEC_AS_MS));
}

// Returns the value in milliseconds
export function getTimeOffset(): number {
  return MIN_AS_MS * new Date().getTimezoneOffset();
}

// Helper formatter.
export function f(x) {
  return format(x, 'yyyy MMM dd, HH:mm:ss');
}


// http://localhost:3000/?animationId=47cbf6b1f319   2vessels
// http://localhost:3000/?animationId=91d68c728b90   a single vessel
