import React from 'react';

import { Track } from './Track';
import { L } from '../../../types/map';
import { TVesselTrack, TVesselTrackPoint } from '../../../types/vessel';

type Props = {
  color: string;
  map: L.Map;
  onClick: (
    vesselId: number,
    point: TVesselTrackPoint,
    latlng: L.LatLng
  ) => void;
  onRemove: (vesselId: number, color: string) => void;
  track: TVesselTrack;
};

/**/
class TrackLine extends React.PureComponent<Props> {
  private track: Track;

  componentDidMount() {
    this.create();
  }

  componentDidUpdate() {
    this.track?.remove();
    this.create();
  }

  componentWillUnmount() {
    const { color, track, onRemove } = this.props;
    color && onRemove(track.vesselId, color);
    this.track?.remove();
  }

  create() {
    const { color, map, track, onClick } = this.props;
    if (track.data.length < 1) return;

    this.track = new Track({
      color: color,
      map: map,
      onClick: onClick,
      path: track,
      showDots: false
    });
  }

  render() {
    return null;
  }
}

/**/
export default TrackLine;
