import { L } from '../../../types/map';
import { TVesselTrack, TVesselTrackPoint } from '../../../types/vessel';

/**
 * The `color` option defines whether the track should be monochromatic.
 * If the `color` hasn't been specified fragments of the track will be
 * painted according to the vessel speed.
 */
export type TTrackOptions = {
  color?: string;
  map: any;
  onClick: (
    vesselId: number,
    point: TVesselTrackPoint,
    latlng: L.LatLng
  ) => void;
  path: TVesselTrack;
  showDots: boolean;
};

export type Chunk = {
  data: TVesselTrackPoint[];
  color: string;
};

export enum ESpeed {
  'S0',
  'S1',
  'S2',
  'S3',
  'S4',
  'S5',
  'S6',
  'S7',
  'S8'
}

export const COLOR_MAP = {
  [ESpeed.S0]: '#0216C8',
  [ESpeed.S1]: '#4E74FC',
  [ESpeed.S2]: '#4DB3FF',
  [ESpeed.S3]: '#00E6B8',
  [ESpeed.S4]: '#B3F95B',
  [ESpeed.S5]: '#FFFF00',
  [ESpeed.S6]: '#FFD525',
  [ESpeed.S7]: '#FF8717',
  [ESpeed.S8]: '#FF0000'
};
