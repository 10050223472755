import React, { ChangeEvent, ReactElement } from 'react';

import './PlayerControl.css';
import ControlGroup from '../ControlGroup/ControlGroup';
import DateRange from '../DateRange/DateRange';
import { formatDateTime, getCurrentMs, getMaxIndex } from '../../other/helpers';
import RateControl from '../RateControl/RateControl';
import SpeedLegend from './SpeedLegend';
import TimeFlow from '../../other/TimeFlow';
import TimeLine from '../TimeLine/TimeLine';

import { TPlayerState } from '../../types/time';

type Props = {
  flow: TimeFlow;
  maxTime: number;
  minTime: number;
  onColoring: (isColored: boolean) => void;
  onStartEnd: (startTime: number, endTime: number) => void;
  playerState: TPlayerState;
};


class PlayerControl extends React.PureComponent<Props> {
  handleTrack = (e: ChangeEvent<HTMLInputElement>) => this.props.onColoring(e.target.checked);

  renderLabels(): ReactElement {
    const { currentIndex, endTime, startTime } = this.props.playerState;
    const _current = getCurrentMs(startTime, currentIndex);
    const current = formatDateTime(
      _current > endTime ? endTime : _current
    );

    return (
      <footer className="PlayerControl__footer">
        <div className="PlayerControl__stamps">
          <div>
            <i>Start: </i>
            <code>{formatDateTime(startTime)}</code>
          </div>

          <div>
            <i>Current: </i>
            <code>{current}</code>
          </div>

          <div>
            <i>End: </i>
            <code>{formatDateTime(endTime)}</code>
          </div>
        </div>

        <SpeedLegend />
      </footer>
    );
  }


  render() {
    const { flow, maxTime, minTime, onStartEnd, playerState } = this.props;

    if (!flow) {
      return (
        <section className="PlayerControl__loader">
          Loading vessel data...
        </section>
      );
    }

    const { currentIndex, endTime, playMode, startTime } = playerState;
    const maxIndex = getMaxIndex(startTime, endTime);

    return (
      <section className="PlayerControl">
        <div className="PlayerControl__control">
          <ControlGroup
            onFastForward={flow.fastForward}
            onPause={flow.pause}
            onPlay={flow.play}
            onRewind={flow.rewind}
            playMode={playMode}
          />

          <DateRange
            endTime={endTime}
            maxTime={maxTime}
            minTime={minTime}
            onStartEnd={onStartEnd}
            startTime={startTime}
          />

          <RateControl onRate={flow.setPlayRate} playRate={playerState.playRate} />

          <div className="PlayerControl__switch">
            <input
              className="PlayerControl__checkbox"
              id="checkbox"
              onChange={this.handleTrack}
              type="checkbox"
            />

            <label htmlFor="checkbox">Speed track</label>
          </div>
        </div>

        <TimeLine
          maxValue={maxIndex}
          onChange={flow.setCurrentIndex}
          value={currentIndex}
        />

        {this.renderLabels()}
      </section>
    );
  }
}


export default PlayerControl;
